<template>
  <div>
    <div class="container">
      <!-- 背景 -->
      <img class="back-ground-image" src="@/assets/image/idx-kaoheLC.png" alt="" />
      <!-- 顶部 -->
      <div class="header">数字东泰</div>
      <!-- 返回 刷新 -->
      <rightNav></rightNav>
      <!-- 时间 -->
      <div class="time-box">
        <Date></Date>
      </div>
      <div class="renkou-top">
        <div class="renkou-top-L">
          <!-- 信件来源 -->
          <!-- <XinjianLy></XinjianLy> -->
        </div>
        <div class="renkou-top-M">
            <div class="rekou_top">
                东泰社区编外工作人员量化考核流程
            </div>
          <!-- 东泰社区司法调解汇总 -->
          <div class="img_LCT">
              <img src="@/assets/image/kaoheLCT.png" alt="">
          </div>
        </div>
        <div class="renkou-top-R">
          <!-- 数量同比 -->
          <!-- <NumTb></NumTb> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Demo from "@/views/judicialMediation/components/demo.vue";
import Date from "@/views/commonComponents/date.vue";
import XinjianLy from "@/views/judicialMediation/components/xinjianLy.vue";
import TiaojieHz from "@/views/judicialMediation/components/tiaojieHz.vue";
import NumTb from "@/views/judicialMediation/components/numTb.vue";
import EventsPlace from "@/views/judicialMediation/components/eventsPlace.vue";
import rightNav from "@/views/commonComponents/rightNav.vue";
export default {
  components: {
    Demo,
    Date,
    EventsPlace,
    XinjianLy,
    TiaojieHz,
    NumTb,
    rightNav,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: auto;
  overflow: hidden;
  .back-ground-image {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  // 顶部
  .header {
    width: 100%;
    height: 10vh;
    line-height: 10vh;
    letter-spacing: 4vw;
    padding-left: 5vw;
    // background: linear-gradient(to top, #c0d9ff, #fff);
    background: linear-gradient(to top, #c0d9ff, #fff);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 3vw;
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: bold;
    // color: #FFFFFF;
    text-shadow: 0px 10px 7px rgba(0, 0, 0, 0.17);
  }
  .time-box {
    width: 25vw;
    height: 7vh;
    // border: 1px solid teal;
    position: fixed;
    top: 7vh;
    right: 3vw;
    z-index: 99;
  }
  // top
  .renkou-top {
    width: 100vw;
    height: 51vh;
    // border: 1px solid tomato;
    .renkou-top-L {
      width: 28vw;
      height: 51vh;
      //   border: 1px solid tan;
      margin-left: 2vw;
      float: left;
    }
    .renkou-top-M {
      width: 40vw;
    //   height: 100vh;
    overflow: hidden;
    //   border: 1px solid tan;
      margin-left: 4.3vw;
      margin-top: 7vh;
      float: left;
      .rekou_top{
            // text-align: center;
            margin-left: 10vw;
            font-size: 1vw;
            height: 6vh;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFD901;
      }
      .img_LCT{
          width: 100%;
        //   height: 100%;
          img{
              width: 100%;
              height: 100%;
          }
      }
    }
    .renkou-top-R {
      width: 22.5vw;
      height: 45vh;
      float: left;
      margin-left: 4.5vw;
      //   border: 1px solid tan;
      margin-top: 6vh;
    }
  }
  //   bottom
  .renkou-bottom {
    width: 100vw;
    height: 31vh;
    // border: 1px solid tomato;
    margin-top: 6vh;
    position: relative;
  }
}
</style>
